import React from 'react';
import { Button } from 'react-bootstrap';
import searchIcon from '../../../assets/images/search-black.svg';
import plusIcon from '../../../assets/images/plus.svg';

const SearchAndButtons = (props) => {
  const {
    onSearchChange,
    onButtonClick,
    searchPlaceholder,
    buttonName,
    seeAllHandle,
    seeAllButton,
    isButtonDisabled,
    showSearchBox,
    seeAll,
    loading,
  } = props;
  return (
    <div className="row">
      <div className="col-md-6">
        {showSearchBox && (
          <div className="search_main">
            <div className="d-flex align-items-center w-100 ">
              <span className="ms-3">
                <img src={searchIcon} alt="search" height="24" width="24" />
              </span>
              <input
                className="form-control me-2  border-0 shadow-none"
                type="search"
                placeholder={searchPlaceholder}
                aria-label="Search"
                onChange={(e) => onSearchChange(e.target.value)}
                disabled={loading}
              />
            </div>
          </div>
        )}
      </div>
      <div className="col-md-6 text-end d-flex justify-content-end">
        {seeAllButton && (
          <div className="form-check mt-3 m-2">
            <input
              className="form-check-input check_box"
              type="checkbox"
              id="flexCheckChecked"
              onChange={seeAllHandle}
              checked={seeAll}
            />

            <label
              className="form-check-label fs-5 checkbox_label"
              htmlFor="flexCheckChecked"
            >
              See All
            </label>
          </div>
        )}
        {buttonName && (
          <div style={{ paddingLeft: '1.5rem' }}>
            <Button
              type="submit"
              className="btn-green d-flex align-items-center ms-auto fw-bold"
              onClick={onButtonClick}
              disabled={isButtonDisabled}
            >
              {/* <img
              src={plusIcon}
              width="24"
              height="24"
              alt="pencil"
              className="me-2"
            /> */}
              {buttonName}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

SearchAndButtons.defaultProps = {
  seeAllButton: true,
  isButtonDisabled: false,
  showSearchBox: true,
};
export default SearchAndButtons;
