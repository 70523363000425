import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeleteConfirmationModal = (props) => {
  const {
    show,
    centered,
    name,
    module,
    handleToggle,
    handleDelete,
    wording,
    title,
    buttonName,
  } = props;
  return (
    <Modal
      show={show}
      onHide={handleToggle}
      centered={centered || true}
      className="custom_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="text-danger fs-6">
            {title ? title : `Delete ${module}`}
          </h5>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {wording
          ? wording
          : `Are you sure you want to delete ${name} ${module}?`}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="sky" size="sm" onClick={handleToggle}>
          Close
        </Button>
        <Button size="sm" onClick={handleDelete}>
          {buttonName ? buttonName : `Delete`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmationModal;
