import * as network from './network';

// Category Apis
const getAllCategory = (params) => {
  return network.get(`category/all`, params, true);
};

const getCategoriesOnSearch = (params) => {
  return network.get(`category/search`, params, true);
};

const addCategory = (payload) => {
  return network.post(`category`, payload, true);
};

const updateCategory = (id, payload) => {
  return network.put(`category/${id}`, payload, true);
};

const updateCategoryStatus = (payload) => {
  return network.put(`category/status`, payload, true);
};

const deleteCategory = (id) => {
  return network.delet(`category/${id}`, true);
};

// Sub Category Apis
const getAllSubcategory = (params) => {
  return network.get(`sub-category/all`, params, true);
};

const getSubcategoriesOnSearch = (params) => {
  return network.get(`sub-category/search`, params, true);
};

const addSubcategory = (payload) => {
  return network.post(`sub-category`, payload, true);
};

const updateSubcategory = (id, payload) => {
  return network.put(`sub-category/${id}`, payload, true);
};

const deleteSubcategory = (id) => {
  return network.delet(`sub-category/${id}`, true);
};

const getProductsBySubcategory = (id) => {
  return network.get('product/sub-cat/' + id);
};

const removeRestrictedMeds = (params) => {
  return network.delet('sub-category/restricted-meds', true, params);
};

export default {
  // Category Section
  getAllCategory,
  getCategoriesOnSearch,
  addCategory,
  updateCategory,
  updateCategoryStatus,
  deleteCategory,

  // SubCategory Section
  getAllSubcategory,
  getSubcategoriesOnSearch,
  addSubcategory,
  updateSubcategory,
  deleteSubcategory,
  getProductsBySubcategory,
  removeRestrictedMeds,
};
