import moment from "moment";
import topBanner from "../assets/images/top_layer.png";
import emailIcon from "../assets/images/icon1.png";
import phoneIcon from "../assets/images/icon2.png";
import bottomBanner from "../assets/images/bottom.png";

export const PRODUCT_TYPES = [
  {
    label: "Product",
    value: "product",
  },
  {
    label: "Medication",
    value: "medication",
  },
];

export const PRODUCT_STATUS = [
  {
    label: "Active",
    value: true,
  },
  {
    label: "Inactive",
    value: false,
  },
];

export const ROLE_TYPES = [
  {
    label: "Admin",
    value: "admin",
  },
  {
    label: "Doctor",
    value: "doctor",
  },
  {
    label: "Pharmacist",
    value: "pharmacist",
  },
];

export const GENDER_OPTIONS = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Other", value: "other" },
];

export const PRODUCT_STATUS_OPTIONS = [
  { label: "Active", value: "active", actualValue: true },
  { label: "Inactive", value: "inactive", actualValue: false },
];

export const VAT_RATE_OPTIONS = [
  { label: "0%", value: 0 },
  { label: "5%", value: 5 },
  { label: "20%", value: 20 },
];

export const METADATA_NAME_OPTIONS = [
  { label: "Keyword", value: "keyword" },
  { label: "Title", value: "title" },
  { label: "Description ", value: "description" },
];

export const PRODUCT_OPTIONS = [
  { label: "products", value: "products" },
  { label: "brands", value: "brands" },
  { label: "categories", value: "categories" },
];

export const VARIANT_ATTRIBUTE_OPTIONS = [
  { label: "Strength", value: "Strength" },
  { label: "Quantity", value: "Quantity" },
  { label: "Package_Size", value: "Package_Size" },
  { label: "Colour", value: "Colour" },
  { label: "Generic_or_Branded", value: "Generic_or_Branded" },
  { label: "Dose", value: "Dose" },
  { label: "Type", value: "Type" },
];

export const DIMENSION = {
  product: {
    height: 400,
    width: 400,
  },
  bigBanner: {
    height: 410,
    width: 1296,
  },
  smallBanner: {
    height: 130,
    width: 250,
  },
  blog: {
    height: 300,
    width: 1196,
  },
  category: {
    height: 312,
    width: 186,
  },
  subCategory: {
    height: 400,
    width: 400,
  },
  profile: {
    height: 100,
    width: 100,
  },
  healthGoal: {
    height: 300,
    width: 300,
  },
  landingPage: {
    height: 600,
    width: 600,
  },
};
export const GenderByKey = {
  male: "Male",
  female: "Female",
  other: "Other",
};

export const BANNER_TYPE = [
  {
    label: `Big Banner - w x h : ${DIMENSION.bigBanner.width} x ${DIMENSION.bigBanner.height}`,
    value: "big",
  },
  {
    label: `Small Banner - w x h : ${DIMENSION.smallBanner.width} x ${DIMENSION.smallBanner.height}`,
    value: "small",
  },
];

export const deliveryOptions = [
  { label: "Royal Mail", value: "royal_mail" },
  { label: "Quiver", value: "quiver" },
  { label: "VIP", value: "vip" },
];
export const statusOptions = [
  {
    label: "Placed",
    value: "placed",
  },
  {
    label: "Dispatched",
    value: "dispatched",
  },
  {
    label: "Cancelled",
    value: "cancelled",
  },
];

export const drugLabelHTML = (data) => {
  const html = `<div style="padding:10px;width:227px;">
                  <p style="font-size: 9px;text-decoration:underline;font-weight:bold;text-align:center;">
                    ${data?.product?.name}
                  </p>
                  <p style="height:50px;font-size: 7px;font-weight:bold;text-align:center;margin-top:5px;">
                    ${
                      data?.instruction && data.instruction != undefined
                        ? data.instruction
                        : ""
                    }
                  </p>
                  <p style="margin-top:5px;display:flex;align-items:center;justify-content:space-between;">
                    <span style="font-size: 9px;font-weight:bold;text-align:left;">
                      ${data?.user?.first_name} ${data?.user?.last_name}
                    </span>
                    <span style="font-size: 7px;font-weight:bold;text-align:right;">
                      ${moment(data?.createdAt).format("DD/MM/YYYY")}
                    </span>
                  </p>
                  <p style="margin-top:10px;text-align:center;font-size: 7px;font-weight:bold;">
                    MLP Ltd
                  </p>
                  <p style="text-align:center;font-size: 6px;font-weight:bold;">
                    45 Newman Street, London W1T 1QE
                  </p>
                  <p style="text-align:center;font-size: 6px;font-weight:bold;color:red;">
                    Keep out of reach and sight of children
                  </p>
                </div>`;
  return html;
};

export const deliveryLabel = (data) => {
  const html = `<div class="mt-2 mx-2">
                  <p style="font-size: 15px">
                    ${data?.user?.first_name} ${data?.user?.last_name}
                  </p>
                  <hr />
                  <p style="font-size: 12px">
                    ${
                      data?.address?.address_line2
                        ? `${data?.address?.address_line1}, ${data?.address?.address_line2}`
                        : `${data?.address?.address_line1}`
                    },
                  </p>
                  <p style="font-size: 12px">
                    ${data?.address?.address_line1},
                  </p>
                  <p style="font-size: 12px">
                    ${data?.address?.city}, ${data?.address?.country}
                  </p>
                </div>`;
  return html;
};

export const prescriptionTemplate = (data) => {
  const doctorInfo = data?.orderItem?.reduce(
    (prev, curr, index) => {
      if (
        curr?.product?.type === "medication" &&
        curr?.answer_status == "Approved"
      ) {
        let variantInfo = curr?.variant?.attributes
          ? Object.keys(curr?.variant?.attributes).reduce(
              (previous, currentAttr) => {
                return (
                  previous +
                  `<div><span class="fw-bold">${currentAttr} : </span>${curr.variant.attributes[currentAttr]}</div>`
                );
              },
              ``
            )
          : "";

        prev.medicinesInfoHTML =
          prev.medicinesInfoHTML +
          `<div style="margin-top:12px"> <span class="fw-bold">MEDICINE : </span>
              ${
                curr?.product?.name == "Ozempic"
                  ? "Wegovy"
                  : curr?.product?.name
              }</div>
          ${variantInfo} 
          ${
            curr?.instruction
              ? `<div>
              <span class="fw-bold">DOSE INFORMATION : </span> 
              ${curr?.instruction}
            </div>`
              : ""
          }`;

        let doctorName =
          curr?.doctor?.first_name && curr?.doctor?.first_name != undefined
            ? `${curr?.doctor?.first_name} ${curr?.doctor?.last_name}`
            : "";
        let doctorSign =
          curr?.doctor?.doctor_e_sign != undefined
            ? curr?.doctor?.doctor_e_sign
            : "";
        if (!prev.doctorName.includes(doctorName)) {
          prev.doctorName =
            prev.doctorName === ""
              ? doctorName
              : `${prev.doctorName}, ${doctorName}`;
          prev.doctorSign =
            prev.doctorSign === ""
              ? doctorSign
              : `${prev.doctorSign}, ${doctorSign}`;
          return prev;
        } else {
          return prev;
        }
      }
      return prev;
    },
    { doctorName: "", doctorSign: "", medicinesInfoHTML: "" }
  );

  let doctorApprovalDate = data?.status.filter(
    (el) => el.actionType == orderActions?.medStatus
  );

  doctorApprovalDate = doctorApprovalDate[doctorApprovalDate?.length - 1].date;

  const html =
    `<div style="padding: 20px 20px;" style="width: 100%;">
        <div style="width:406px;">
          <div>
            <img src="${topBanner}" alt="Top Header" style="width:100%; height: auto;" />
          </div>
          <div style="text-align:center;font-weight:bold;width:100%;font-size: 12px;">DISPENSING PRESCRIPTION</div>
          <div style="display:flex;margin-top:10px">
            <div style="padding: 0 10px 0 20px;width:40%;">
              <div style="padding:10px 0px;border-top: 1px solid #77cdc8;font-size:10px;color:#000;line-height:10px">
                <div>PRESCRIPTION NUMBER:</div>
                <div>${data?.prescription_number}</div>
                <div>ORDER NUMBER:</div>
                <div>${data?.order_number}</div>
              </div>
              <div style="padding:10px 0px;border-top: 1px solid #77cdc8;border-bottom: 1px solid #77cdc8;font-size:9px;color:#000;line-height:11px;text-align:center;">
                <div><img src="${emailIcon}" alt="Email Icon" style="width:20px; height: 20px;" /></div>
                <div style="margin-top:4px;">support@harleymedics.com</div>
                <div style="margin-top:10px;"><img src="${phoneIcon}" alt="Phone Icon" style="width:20px; height: 20px;" /></div>
                <div style="margin-top:4px;">+44 1750 491 172</div>
                <div style="margin-top:8px;">45 Newman Street, London W1T 1QE, UK</div>

              </div>
            </div>
            <div style="padding: 0 10px 0 20px;width:60%;">
              <div style="padding:7px 0px;border-top: 1px solid #77cdc8;font-size:11px;color:#000;line-height:10px;text-align:center;">
              <div>${data?.user?.first_name} ${data?.user?.last_name}</div>
              <div>${data?.address?.address_line1}</div>
              <div>${
                data?.address?.address_line2 ? data?.address?.address_line2 : ""
              }</div>
              <div>${data?.address?.city},</div>
              <div>${data?.address?.country}</div>
              <div>${moment(data?.user?.dob).format("DD/MM/YYYY")}</div>
              <div>${GenderByKey[data?.user?.gender]}</div>
            </div>
            <div style="border-top: 1px solid #77cdc8;font-size:9px;color:#000;">` +
    doctorInfo.medicinesInfoHTML +
    `</div>
    </div>
        </div>
        <div style="margin-top:150px">
        <h6 class='text-danger mb-3 fw-bold' style="font-size:10px;">This prescription has been dispensed by ${
          data?.pharmacist?.company_name
        } on ${moment(
      data?.status?.find((el) => el?.status === "Dispatched by Pharmacist")
        ?.date
    ).format("DD/MM/YYYY")}.</h6>
          <table style="font-size:9px;border: 1px solid;width:60%;">
            <tr>
              <td style="border-right: 1px solid;border-bottom: 1px solid;padding:0px 2px;">Prescription Approved By</td>
              <td style="border-bottom: 1px solid;padding:0px 2px;">${
                doctorInfo?.doctorName
              }</td>
            </tr>
            <tr>
              <td style="border-right: 1px solid;border-bottom: 1px solid;padding:0px 2px;">E-Signature ID</td>
              <td style="border-bottom: 1px solid;padding:0px 2px;">${
                doctorInfo?.doctorSign
              }</td>
            </tr>
            <tr>
              <td style="border-right: 1px solid;padding:0px 2px;">Date</td>
              <td style="padding:0px 2px;">${moment(doctorApprovalDate).format(
                "DD/MM/YYYY"
              )}</td>
            </tr>
          </table>
        </div>
        <div style="display:flex;height:20px;margin-bottom:40px;">
          <div style="width:40%;"></div>
          <div style="width:60%;border-bottom: 1px solid #77cdc8;"></div>
        </div>
        <div>
          <img src="${bottomBanner}" alt="Bottom Footer" style="width:100%; height: auto;" />
        </div>
      </div>
    </div>`;
  return html;
};

export const staticIDs = {
  weight: "636bb1a8b316010519546a57",
  height: "636bb1a8b316010519546a55",
  gp_question: "63cf77c0069bf41adade01d7",
};

export const gpAddressSubQuestionIDs = [
  "63cf7a6c069bf41adade02c0", // Main Question
  "63cf9d7d069bf41adade048c", // Address 1
  "63cf9d7d069bf41adade048d", // Address 2
  "63cf9d7d069bf41adade048e", // Address 3
  "63cf9d7d069bf41adade048f", // City
  "63cf9d7d069bf41adade0490", // Postcode
  "63cf9d7d069bf41adade0491", //  GP email address
];

export const gpEmailKey = "63cf9d7d069bf41adade0491";
export const gpNameKey = "63cf7a6c069bf41adade02c0";

export const subQuestionIDAndKeys = {
  "63cf9d7d069bf41adade048c": "Address1",
  "63cf9d7d069bf41adade048d": "Address2",
  "63cf9d7d069bf41adade048e": "Address3",
  "63cf9d7d069bf41adade048f": "City",
  "63cf9d7d069bf41adade0490": "Postcode",
  "63cf9d7d069bf41adade0491": "email",
};

export const gpAddressSubQuestionIDAndKeys = {
  "63cf7a6c069bf41adade02c0": "Main Question",
  "63cf9d7d069bf41adade048c": "Address 1",
  "63cf9d7d069bf41adade048d": "Address 2",
  "63cf9d7d069bf41adade048e": "Address 3",
  "63cf9d7d069bf41adade048f": "City",
  "63cf9d7d069bf41adade0490": "Postcode",
  "63cf9d7d069bf41adade0491": "Email",
};

export const orderActions = {
  orderStatus: "orderStatus",
  Quantity: "qty",
  medStatus: "medStatus",
  pharmacyReject: "pharReject",
  assignment: "assignment",
};

export const headerTabs = {
  productTab: [
    "product",
    "category",
    "keyword",
    "brand",
    "featured-product",
    "general-health-questions",
    "health-goals",
  ],
  promotionTab: ["brand-promotion", "product-promotion", "offer-title"],
  orderTab: [
    "orders",
    "orders-pending",
    "orders-dispatched",
    "orders-cancelled",
    "pharmacist-rejected",
    "lexisnexis-rejected",
    "orders-approved",
    "orders-assigned",
    "orders-subscription",
    "orders-subscription-cancelled",
    "orders-gpLetterSection",
  ],
  userTab: ["admin-doctor", "users", "duplicate-users"],
  stockTab: ["stock-upload", "stock-scan"],
  reportingTab: ["pharmacist-reporting", "accounting", "doctor-reporting"],
  settingTab: ["user-profile"],
  gpLetters: ["gp-letters", "gp-letters-download"],
};

export const SubscriptionSpanOptions = [
  { label: "1 Month", value: "1_month" },
  { label: "3 Months", value: "3_months" },
];

export const orderSearchbarPlaceholder =
  "Search by order number, user name, email and order item...";

export const RESTRICT_STATEMENT =
  "Your response suggests that this medication may not be appropriate for online purchase. For further advice, please contact us or your GP.";
