import React, { useEffect, useState } from "react";
import ViewOrderDetails from "./orderDetails";
import CustomerDetails from "./customerDetails";
import ProductQuestions from "./productQuestions";
import { useSelector } from "react-redux";
import api from "../../../api/api";
import { useDispatch } from "react-redux";
import { emptyOrderData, getOrderById } from "../../../store/reducers/order";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toastSuccess } from "../../../common/UI/Toasts";
import {
  isUserAdmin,
  isUserDoctor,
  isUserPharmacist,
} from "../../../utils/helpers";
import InternalNotes from "../ViewOrder/internalNotes";

const ViewOrderProductPage = () => {
  const orderData = useSelector((state) => state.order.orderData);
  const { id, variantId } = useParams();
  const dispatch = useDispatch();
  const [thisOrderItem, setThisOrderItem] = useState();
  const [instruction, setInstruction] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [orderImages, setOrderImages] = useState();

  useEffect(() => {
    if (orderData) {
      let thisProductData = orderData?.orderItem.find(
        (el) => el.variant._id === variantId
      );
      setThisOrderItem(thisProductData);
      setInstruction(thisProductData?.instruction || "");
      api.getOrderImages(orderData._id).then((res) => {
        setOrderImages(res.data.data[0]);
      });
    }
  }, [orderData]);

  const onChangeOfInstructionInput = (e) => {
    setInstruction(e.target.value);
  };

  const saveInstructions = () => {
    const payload = {
      instruction: instruction,
    };

    api
      .setInstructionForProductLabel(thisOrderItem?._id, payload)
      .then((res) => {
        toastSuccess("Added Instruction Successfully");
        dispatch(getOrderById(id));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <ol className="breadcrumb">
            <li>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch(emptyOrderData());
                  navigate(
                    location.state?.allOrdersPath
                      ? location.state?.allOrdersPath
                      : -2,
                    {
                      state: {
                        ...location.state,
                      },
                    }
                  );
                }}
                title="Product Management"
              >
                All Orders
              </p>
            </li>
            <li>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/vieworder/${id}`);
                }}
                title="Product Management"
              >
                View Order
              </p>
            </li>
            <li>View Order Product</li>
          </ol>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-xl-12 col-xxl-6 mb-3">
          <ViewOrderDetails key={orderData?._id} orderData={orderData} />
        </div>
        <div className="col-md-12 col-xl-12 col-xxl-6 mb-3">
          <CustomerDetails key={orderData?._id} orderData={orderData} />
        </div>
      </div>
      {orderImages?.userPicture && (
        <div className="box_main">
          <div className="header_top">
            <h3>Full Body Picture & Photo Id</h3>
          </div>
          <div className="row order-images-section">
            {orderImages?.userPicture?.isdeleted ? (
              <p>User Image was uploaded</p>
            ) : (
              <img
                src={orderImages?.userPicture?.location}
                alt=""
                style={{
                  borderRadius: "16px",
                  width: "300px",
                  height: "150px",
                  objectFit: "contain",
                }}
              />
            )}
            {orderImages?.userIdPicture?.isdeleted ? (
              <p>User ID was uploaded</p>
            ) : (
              <img
                src={orderImages?.userIdPicture?.location}
                alt=""
                style={{
                  borderRadius: "16px",
                  width: "300px",
                  height: "150px",
                  objectFit: "contain",
                }}
              />
            )}
          </div>
        </div>
      )}
      {thisOrderItem?.product?.type === "medication" ? (
        <div className="row">
          <div className="col-md-12 mb-3">
            <div
              className="header_top "
              style={{ justifyContent: "space-between" }}
            >
              <h3>Add Instructions</h3>
            </div>

            <div className="d-flex">
              <div
                style={{
                  width: "40%",
                }}
              >
                <textarea
                  disabled={isUserPharmacist()}
                  className="mt-4 form-control form-textarea"
                  rows="3"
                  value={instruction}
                  onChange={(e) => onChangeOfInstructionInput(e)}
                  placeholder="Write the instructions here..."
                  style={{ width: "100%", borderRadius: "0.5rem" }}
                />
                {instruction != "" &&
                (!thisOrderItem.instruction ||
                  thisOrderItem.instruction != instruction) ? (
                  <span className="text-danger">
                    {thisOrderItem.instruction &&
                    thisOrderItem.instruction != instruction
                      ? `You have updated the instruction but haven't saved it.`
                      : `Instruction is not saved yet.`}
                  </span>
                ) : null}
              </div>
              <div className="mt-4 ms-3">
                {isUserDoctor() || isUserAdmin() ? (
                  <button
                    className="btn btn-primary mt-3"
                    onClick={saveInstructions}
                    disabled={!instruction || instruction == ""}
                  >
                    Save
                  </button>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-12 mb-3">
            <div className="box_main">
              <div className="middle_main">
                <InternalNotes />
              </div>
            </div>
          </div>
          {(isUserDoctor() || isUserAdmin()) && (
            <div className="col-md-12">
              <ProductQuestions
                OrderItem={thisOrderItem}
                orderStatus={orderData.current_order_status}
              />
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default ViewOrderProductPage;
